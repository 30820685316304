/*------*/
/* MAIN */
/*------*/
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");
body {
  background-color: var(--light-black);
  color: var(--white);
  font-family: var(--fira-font);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: var(--code-font);
}
* {
  box-sizing: border-box;
}

:root {
  --white: #f4f4f4;
  --black: #1f2428;
  --light-black: #2a2a2a;
  --grey: #464646;
  --light-grey: #6c6c6c;

  --code-font: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  --fira-font: "Fira Code", monospace;
  --verdana-font: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/*-----------*/
/* CONTAINER */
/* MAIN GRID */
/*-----------*/
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 60px 180px 1fr;
  grid-template-rows: 35px 40px auto;
  grid-template-areas:
    "title title title"
    "side-menu explorer navbar"
    "side-menu explorer home";
}
.title__container {
  grid-area: title;
}
.menu__container {
  grid-area: side-menu;
}
.explorer__container {
  grid-area: explorer;
}
.navbar__container {
  grid-area: navbar;
}
.home__container {
  grid-area: home;
}

/*-----------*/
/* TITLE BAR */
/*-----------*/
.title__container {
  background-color: var(--black);
  display: flex;
  font-family: var(--verdana-font);
}
.title__content--left,
.title__content--center,
.title__content--right {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}
.title__content--left span {
  margin-right: auto;
}
.title__content--right span {
  margin-left: auto;
}
.title__logo {
  margin-left: 0.5rem;
  width: 20px;
}
.title__content--center span {
  font-size: smaller;
}
.title__content--right svg {
  margin: 0 0.5rem;
}
.title__ul {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.title__ul li {
  margin-right: 1rem;
}

/*---------------------*/
/* MENU BIG ICONS VERT */
/*---------------------*/
.menu__container {
  align-items: center;
  color: var(--light-grey);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu__container ul {
  list-style: none;
  padding: 0;
}
.menu__container li {
  margin-bottom: 1rem;
}

/*---------------*/
/* EXPLORER LIST */
/*---------------*/
.explorer__container {
  background-color: var(--black);
  font-family: var(--verdana-font);
}
.explorer__accordion--item {
  align-items: center;
  background-color: var(--light-black);
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 2rem;
  text-transform: uppercase;
}
.explorer__accordion--link {
  align-items: center;
  display: flex;
}
.explorer__accordion--link img {
  margin-right: 0.5rem;
  width: 18px;
}
.explorer__accordion ul {
  list-style: none;
  margin: 0;
  padding: 0 2rem;
}
.explorer__accordion li {
  margin-top: 0.5rem;
}
.explorer__title {
  font-size: small;
  margin-left: 1rem;
}

/*-------------*/
/* NAVBAR TABS */
/*-------------*/
.navbar__container {
  align-self: center;
  font-family: var(--verdana-font);
}
.navbar__container ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.navbar__container li {
  min-width: 120px;
  padding: 0.5rem;
  text-align: center;
}
.nav__link--inactive {
  color: var(--white);
}
.nav__link-menu--inactive {
  color: var(--light-grey);
}
.nav__link--active {
  color: orange;
}
.nav__link {
  display: flex;
  align-items: center;
}
.nav__link img {
  margin-right: 0.5rem;
  width: 18px;
}
a {
  text-decoration: none;
}

/*------*/
/* HOME */
/*------*/
.home__content {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, 100px) 1fr;
  grid-template-areas:
    "number  ."
    "number  ."
    "number  name"
    "number job";
}
.home__content img {
  width: 250px;
  left: 200px;
  opacity: 0.3;
  position: absolute;
  top: 80px;
  z-index: -1;
}
.home__name {
  align-self: flex-end;
  font-size: xx-large;
  grid-area: name;
}
.home__content ul {
  color: var(--light-grey);
  font-size: large;
  grid-area: number;
  list-style: none;
  padding: 0;
}
.home__content li {
  margin-left: 1rem;
  padding: 0.1rem;
}
.home__content span {
  color: var(--white);
}
.home__content button {
  border: none;
  border: 1px solid orange;
  background: transparent;
  color: orange;
  cursor: pointer;
  font-size: large;
  font-family: var(--fira-font);
  margin-right: 1rem;
}
.home__content button:hover {
  background-color: orange;
  color: var(--light-black);
  transition: all 0.2s linear;
}
.home__pulse {
  animation: pulse 1s infinite ease-out;
}
@keyframes pulse {
  0% {
    color: orange;
    opacity: 0;
  }
  100% {
    color: orange;
    opacity: 1;
  }
}

/*----------*/
/* PROJECTS */
/*----------*/
.projects__container {
  padding: 1rem;
}
.projects__content h1 {
  color: orange;
}
.projects__content ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.projects__content button {
  background: transparent;
  border: none;
  border: 1px solid orange;
  cursor: pointer;
  color: orange;
  font-size: large;
  font-family: var(--fira-font);
  margin: 3rem 0.5rem;
}
.projects__content button:hover {
  color: var(--light-black);
  background-color: orange;
  transition: all 0.2s linear;
}
.projects__tags li {
  color: var(--light-grey);
  cursor: pointer;
  margin-right: 1rem;
}
.card {
  background-color: var(--black);
  border-radius: 0.5rem;
  border: 1px solid var(--light-grey);
  box-shadow: 5px 5px 20px -3px black;
  margin: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 110px;
}
.card:hover .card__content {
  opacity: 1;
}
.card__content {
  background-color: var(--black);
  bottom: 0;
  color: var(--white);
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  padding: 0.5rem;
  right: 0;
  transition: 0.5s ease;
  width: 100%;
}
.card__img {
  display: block;
  height: 100px;
  width: 100%;
}
/* cadetblue underline title animation */
.card__title {
  position: relative;
  width: max-content;
}
.card__title::after {
  bottom: -3px;
  background: cadetblue;
  content: "";
  height: 3px;
  left: calc(1rem * -1);
  width: calc(100% + 1rem);
  position: absolute;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 500ms ease;
}
.card:hover .card__title::after {
  transform: scaleX(1);
}
.projects__content label {
  color: var(--light-grey);
}

/*------*/
/* BLOG */
/*------*/
.blog__container {
  padding: 1rem;
  color: var(--light-grey);
}
.blog__content h1 {
  color: orange;
}
.blog__content img {
  clip-path: polygon(
    0% 20%,
    60% 20%,
    60% 0%,
    100% 50%,
    60% 100%,
    60% 80%,
    0% 80%
  );
  cursor: pointer;
  margin-bottom: 1rem;
}
.blog__content img:hover {
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  transition: all 0.3s ease-in;
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(30px);
  }
}
.blog__content section {
  margin-bottom: 1rem;
}
.blog__text {
  color: var(--white);
  margin-left: 1rem;
}
.blog__content button {
  color: orange;
  cursor: pointer;
  background: transparent;
  border: none;
  border: 1px solid orange;
  font-size: large;
  font-family: var(--fira-font);
  margin-left: 1rem;
}
.blog__content button:hover {
  background-color: orange;
  color: var(--light-black);
  transition: all 0.2s linear;
}

/*-------*/
/* POSTS */
/*-------*/
.posts__container {
  padding: 1rem;
}
.posts__container ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.post__content {
  background-color: #feefde;
  border: 1px solid var(--light-grey);
  box-shadow: 5px 5px 20px -3px black;
  border-radius: 0.5rem;
  max-width: 300px;
  overflow: hidden;
}
.post__image-wrapper {
  background-color: var(--black);
  height: 100px;
  overflow: hidden;
  width: auto;
}
.post__image {
  height: auto;
  max-width: 100%;
}
.post__image:hover {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  transition: all 500ms linear;
}
.post__box-wrapper {
  background-color: var(--black);
  display: grid;
  padding: 0.5rem;
  height: 200px;
}
.post__title {
  font-size: x-large;
  font-weight: bold;
}
/* cadetblue underline title animation */
.post__title::after {
  content: "";
  background-color: cadetblue;
  display: block;
  height: 5px;
  margin-top: 0.5em;
  width: 30px;
  left: calc(1rem * -1);
  width: 99%;
}
.post__intro {
  font-size: small;
  font-style: italic;
  padding: 0.5rem;
}
.post__page {
  color: var(--light-grey);
  font-size: 10rem;
  opacity: 0.2;
  left: 43%;
  bottom: -50%;
  position: absolute;
}

.post__footer {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
}

/*---------*/
/* ARTICLE */
/*---------*/
.article__container {
  padding: 1rem;
}

/*-------*/
/* ABOUT */
/*-------*/
.about__container {
  color: var(--light-grey);
  padding: 1rem;
}
.about__content h1 {
  color: orange;
}
.about__content img {
  border-radius: 1rem;
  border: 1px solid var(--light-grey);
  width: 200px;
}
.about__content section {
  margin-top: 1rem;
  max-width: 350px;
}
.about__text {
  color: var(--white);
  margin-left: 1rem;
}
.about__links {
  color: cadetblue;
  display: flex;
  padding: 1rem;
}
.about__links a {
  margin-left: 1rem;
}
a {
  color: cadetblue;
}
.about__content button {
  color: orange;
  cursor: pointer;
  background: transparent;
  border: none;
  border: 1px solid orange;
  font-size: large;
  font-family: var(--fira-font);
}
.about__content button:hover {
  background-color: orange;
  color: var(--light-black);
  transition: all 0.2s linear;
}

/*-------*/
/* TOOLS */
/*-------*/
.tools__container {
  color: var(--light-grey);
  padding: 1rem;
}
.tools__content ul {
  list-style: none;
  font-size: large;
  padding: 0;
}
.tools__content h1 {
  color: orange;
}
.tools__content li {
  margin-left: 1rem;
  padding: 0.1rem;
}
.tools__content span {
  color: var(--white);
  margin-left: 1rem;
}
.tools__content img {
  width: 20px;
}

/*--------*/
/* FOOTER */
/*--------*/
.footer__container {
  border-top: 1px solid var(--grey);
  display: grid;
  padding: 0;
}
.footer__terminal ul {
  display: flex;
  list-style: none;
  font-size: large;
  padding: 0;
}
.footer__terminal li {
  color: var(--light-grey);
  margin-left: 1rem;
  font-family: monospace;
}
.footer__terminal--underline {
  color: white;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.footer__copyright {
  margin-left: 1rem;
}
.footer__copyright span {
  color: cadetblue;
}
.footer__webpack--successfully span {
  color: greenyellow;
  font-weight: bold;
}
.footer__webpack {
  margin-left: 1rem;
}
.footer__webpack--issues {
  color: yellowgreen;
}
.footer__http {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 20px 20px;
  margin-left: 3rem;
  grid-template-areas:
    "local-host local-host-span"
    "network network-span";
}
.footer__http-local {
  grid-area: local-host;
  font-weight: bold;
}
.footer__http-network {
  grid-area: network;
  font-weight: bold;
}
.footer__http-local--host {
  grid-area: local-host-span;
}
.footer__http-network--host {
  grid-area: network-span;
}
.footer__icons {
  background-color: var(--grey);
  display: flex;
  height: 27px;
  font-family: var(--verdana-font);
  font-size: smaller;
  justify-content: space-between;
}
.footer__icons ul {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
}
.footer__icons li {
  display: flex;
  margin: 0 0.5rem;
}

.post__wrapper {
  padding: 2%;
}

.post__wrapper .post {
  margin: 0 auto;
}
